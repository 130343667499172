// @flow strict
import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';

type Props = {
  center: boolean,
  pageContext: {
    tag: string,
    category: string,
    currentPage: number,
    prevPagePath: string,
    nextPagePath: string,
    hasPrevPage: boolean,
    hasNextPage: boolean,
    totalPage: number,
  },
};

const Paginator = ({ center, pageContext }: Props) => {
  const {
    currentPage,
    hasNextPage,
    hasPrevPage,
    prevPagePath,
    nextPagePath,
    totalPage,
  } = pageContext;

  const prevLinkText = '<< 前のページ';
  const nextLinkText = '次のページ >>';

  return (
    <Root center={center}>
      {hasPrevPage ? (
        <GatsbyLink to={prevPagePath}>{prevLinkText}</GatsbyLink>
      ) : (
        <DisableLink>{prevLinkText}</DisableLink>
      )}
      <>
        <CurrentPage>{currentPage}</CurrentPage>
        <span> / </span>
        <TotalPage>
          <span>{totalPage}</span>
          <span> ページ</span>
        </TotalPage>
      </>
      {hasNextPage ? (
        <GatsbyLink to={nextPagePath}>{nextLinkText}</GatsbyLink>
      ) : (
        <DisableLink>{nextLinkText}</DisableLink>
      )}
    </Root>
  );
};

const Root = styled.div((props) => ({
  margin: '4rem 0 3rem',
  textAlign: props.center ? 'center' : 'auto',
}));

const GatsbyLink = styled(Link)({
  padding: '1rem',
});

const DisableLink = styled.span({
  color: '#ccc',
  padding: '1rem',
});

const CurrentPage = styled.span({});

const TotalPage = styled.span({});

export default Paginator;
