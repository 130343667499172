// @flow strict
import React from 'react';
import styled from '@emotion/styled';
import { graphql, Link } from 'gatsby';
import { useTheme } from 'emotion-theming';

import Section from '../molecules/Section';
import PhotoFrame from '../molecules/PhotoFrame';
import StatsItem from '../molecules/StatsItem';
import Badge from '../atoms/Badge';
import OrnamentalButton from '../atoms/OrnamentalButton';
import Paginator from '../molecules/Paginator';

type Node = {
  deside: Boolean,
  breed_of_dog: String,
  birthday: String,
  gender: String,
  hair_color: String,
  fields: {
    slug: String,
  },
};

type Edge = {
  node: Node,
};

type Props = {
  data: {
    allMicrocmsPuppy: {
      edges: Array<Edge>,
    },
  },
  pageContext: Object,
};

const PuppyListTemplate = ({ data, pageContext }: Props) => {
  const theme = useTheme();
  const { edges } = data.allMicrocmsPuppy;

  return (
    <Section title="仔犬情報一覧">
      <Paginator pageContext={pageContext} center />
      {edges.map((edge, index) => (
        <div key={index.toString()}>
          <PhotoLayout>
            <PhotoFrame>
              <img src={edge.node.photo.url} alt="画像" />
            </PhotoFrame>
          </PhotoLayout>
          {edge.node.decide ? (
            <CenterBadgeLayout>
              <Badge bgColor={theme.colors.secondary} text="オーナー様決定" wide />
            </CenterBadgeLayout>
          ) : null}
          <Root>
            <StatsItem label="犬種" value={edge.node.breed_of_dog} />
            <StatsItem label="生年月日" value={edge.node.birthday} />
            <StatsItem label="性別" value={edge.node.gender} />
            <StatsItem label="毛色" value={edge.node.hair_color} />
          </Root>
          <ButtonLayout>
            <Link to={`/puppy/${edge.node.fields.slug}`}>
              <OrnamentalButton text="詳しく見る" />
            </Link>
          </ButtonLayout>
        </div>
      ))}
      <Paginator pageContext={pageContext} center />
    </Section>
  );
};

const Root = styled.div((props) => ({
  margin: '0 auto',
  maxWidth: props.theme.breakpoints.tablet,
}));

const PhotoLayout = styled.div((props) => ({
  margin: '0 auto 2rem',
  maxWidth: props.theme.breakpoints.tablet / 2,
}));

const CenterBadgeLayout = styled.div({
  margin: '2rem 0',
  textAlign: 'center',
});

const ButtonLayout = styled.div({
  margin: '2rem auto 3rem',
  textAlign: 'center',
});

export const query = graphql`
  query($postsLimit: Int!, $postsOffset: Int!) {
    allMicrocmsPuppy(limit: $postsLimit, skip: $postsOffset) {
      edges {
        node {
          fields {
            slug
          }
          decide
          breed_of_dog
          birthday
          gender
          hair_color
          photo {
            url
          }
        }
      }
    }
  }
`;

export default PuppyListTemplate;
